import React from "react";
import { Box } from "@mui/system";
import { ContentPanel, Row } from "../component_library/layout";
import { Image } from "../component_library/image";
import { useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import { IMAGE_PUBLIC_PATH } from "../constants";

export function DisasterBlasterWikiBanner({backgroundColor}) {
    const panelBackgroundColor = backgroundColor? backgroundColor : "#000000";
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

    return (

        <Box sx={{ marginTop: "5rem" }}>
            <ContentPanel
                backgroundImageSrc={`${IMAGE_PUBLIC_PATH}/disaster-blaster-background.png`}
                color={panelBackgroundColor}
                padding={"0rem"}
            >
                <Link to="/disasterblaster/wiki">
                <Image src={`${IMAGE_PUBLIC_PATH}/disaster_blaster_wiki_logo.png`} scale={isSmallScreen ? 1.2 : 1.5} animated={false} />
                </Link>
                <></>
            </ContentPanel>
        </Box>

    );
}
