import React from "react";
import {
  Navbar,
  NavbarTitle,
  NavbarTitleIcon,
  NavbarItem,
  NavbarItemIcon,
  NavbarDivider,
  NavbarItemText
} from "../component_library/navigation";
import LanguageIcon from "@mui/icons-material/Language";
import HomeIcon from "@mui/icons-material/Home";
import AboutIcon from "@mui/icons-material/Info";
import ContactIcon from "@mui/icons-material/ContactMail";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import NewspaperIcon from '@mui/icons-material/Newspaper';
import FolderIcon from '@mui/icons-material/Folder';
import { Dropdown } from "../component_library/inputs";
import MenuItem from "@mui/material/MenuItem";
import { Localization } from "../component_library/localization";
import { useTheme } from "@emotion/react";
import { ReactComponent as ItalyFlagIcon } from "../vectors/flag-italy.svg";
import { ReactComponent as SpainFlagIcon } from "../vectors/flag-spain.svg";
import { ReactComponent as FranceFlagIcon } from "../vectors/flag-france.svg";
import { ReactComponent as JapanFlagIcon } from "../vectors/flag-japan.svg";
import { ReactComponent as ChinaFlagIcon } from "../vectors/flag-china.svg";
import { ReactComponent as GermanyFlagIcon } from "../vectors/flag-germany.svg";
import { ReactComponent as UnitedStatesFlagIcon } from "../vectors/flag-usa.svg";
import { IMAGE_PUBLIC_PATH } from "../constants";

function LanguageDropdown() {
  const onChange = (event) => {
    Localization.language = event.target.value;
    //update the react page rendering
    window.location.reload();
  };
  const iconSize = 24;
  return (
    <Dropdown defaultValue={Localization.language} onChange={onChange}>
      <MenuItem value="de">
        <GermanyFlagIcon width={iconSize} height={iconSize} />
        DE</MenuItem>
      <MenuItem value="en">
        <UnitedStatesFlagIcon width={iconSize} height={iconSize} />
        EN</MenuItem>
      <MenuItem value="es">
        <SpainFlagIcon width={iconSize} height={iconSize} />
        ES</MenuItem>
      <MenuItem value="fr">
        <FranceFlagIcon width={iconSize} height={iconSize} />
        FR</MenuItem>
      <MenuItem value="it">
        <ItalyFlagIcon width={iconSize} height={iconSize} />
        IT
      </MenuItem>
      <MenuItem value="ja">
        <JapanFlagIcon width={iconSize} height={iconSize} />
        JA</MenuItem>
      <MenuItem value="zh">
        <ChinaFlagIcon width={iconSize} height={iconSize} />
        ZH</MenuItem>
    </Dropdown>
  );
}

export default function Header() {
  const theme = useTheme();

  const transparentColor = "rgba(0, 0, 0, 0)";
  const primaryColor = theme.palette.background.default;
  const titleIconHeight = 64;
  return (
    <>
      <Navbar color={primaryColor} elevation={1} fullWidth={true}>
        <NavbarTitle to="/">
          <NavbarTitleIcon>
            <img src={`${IMAGE_PUBLIC_PATH}/jarsick_logo_and_name.png`} alt="logo" width={titleIconHeight * 3} height={titleIconHeight} />
          </NavbarTitleIcon>
        </NavbarTitle>
        <NavbarItem to="/">
          <NavbarItemIcon>
            <HomeIcon />
          </NavbarItemIcon>
          {Localization.get("home_button")}
        </NavbarItem>
        <NavbarItem to="/#about">
          <NavbarItemIcon>
            <AboutIcon />
          </NavbarItemIcon>
          {Localization.get("about_button")}
        </NavbarItem>
        <NavbarItem>
          <NavbarItemIcon>
            <SportsEsportsIcon />
          </NavbarItemIcon>
          {Localization.get("games_button")}
          <NavbarItem to={"/disasterblaster"}>
            {Localization.get("disaster_blaster_button")}
          </NavbarItem>
        </NavbarItem>
        <NavbarItem to="/#news">
          <NavbarItemIcon>
            <NewspaperIcon />
          </NavbarItemIcon>
          {Localization.get("news_button")}
        </NavbarItem>
        <NavbarItem to="/#contacts">
          <NavbarItemIcon>
            <ContactIcon />
          </NavbarItemIcon>
          {Localization.get("contact_button")}
        </NavbarItem>
        <NavbarDivider />
        <NavbarItem>
          <NavbarItemIcon>
            <LanguageIcon />
          </NavbarItemIcon>
          <LanguageDropdown />
        </NavbarItem>
        <NavbarItem>
        <NavbarItemIcon>
            <FolderIcon />
          </NavbarItemIcon>
          {Localization.get("press_button")}
          <NavbarItem to={"https://impress.games/press-kit/jarsick"}>
            {Localization.get("jarsick_press_button")}
          </NavbarItem>
          <NavbarItem to={"https://impress.games/press-kit/jarsick/disaster-blaster"}>
            {Localization.get("disaster_blaster_press_button")}
          </NavbarItem>
        </NavbarItem>
      </Navbar>
    </>
  );
}
