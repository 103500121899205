import { Scale } from "@mui/icons-material";
import { Box, Button, colors, Icon, IconButton, SvgIcon, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

export const JarsickWebsiteButton = ({ onClick, color, size = "medium", sx, children }) => {

    const hoverStyle = {
        boxShadow: `0 0 .4rem white, 0 0 .4rem white, 0 0 2rem ${color}, inset 0 0 0.7rem ${color}`,
        textShadow: `0 0 .1rem white`,
    };

    const theme = useTheme();
    const buttonColor = color || theme.palette.primary.main;

    return (
        <Button
            onClick={onClick}
            variant="contained"
            size={size}
            sx={{
                ...sx,
                height: '3rem',
                backgroundColor: `${buttonColor}`,
                border: `2px solid ${buttonColor}`,
                '&:hover': hoverStyle,
            }}>
            {children}
        </Button>
    );
}

export const JarsickWebsiteIconButton = ({ icon, onClick, neonColor, sx, blink = false }) => {
    // Clona l'icona e applica il filtro drop-shadow solo sull'elemento svg

    const theme = useTheme();
    const neon = neonColor || theme.palette.primary.main;

    const adjustedIcon = React.cloneElement(icon, {
        style: {
            width: '100%',
            height: '100%',
            stroke: 'white',
        },
    });

    return (
        <IconButton
            onClick={onClick}
            disableFocusRipple
            sx={{
                aspectRatio: "1 / 1",
                width: "100%",
                height: "100%",
                outline: "none",
                boxShadow: "none",
                backgroundColor: "transparent",

                "svg": {
                    filter: `drop-shadow(0 0 .1rem ${neon}) 
                             drop-shadow(0 0 .2rem ${neon})`, // Filtro di base
                    animation: blink
                        ? `blink 1s infinite alternate`
                        : "none", // Animazione lampeggio opzionale
                    transition: "filter 0.2s ease", // Transizione del filtro
                },
                "&:hover svg": {
                    filter: `drop-shadow(0 0 .1rem white) 
                               drop-shadow(0 0 .2rem white) 
                               drop-shadow(0 0 .3rem ${neon}) 
                               drop-shadow(0 0 .4rem ${neon})`, // Intensifica il filtro durante l'hover
                    transition: "filter 0.2s ease", // Assicura una transizione fluida anche durante l'hover
                    animation: "none", // Disabilita l'animazione lampeggiante durante l'hover
                },
                ...sx,
            }}
        >
            {adjustedIcon}
        </IconButton>
    );
};


