import React from "react";
import {
  Container,
  Grid,
  useMediaQuery,
  useTheme,
  Typography,
  Fade,
  Grow,
  Slide,
  Collapse,
  Stack,
  Box,
} from "@mui/material";
import { useContext } from "react";
import { StyleContext, StyleProvider } from "./contexts";

// TODO: TROVARE UN MODO PER METTERE IMMAGINI DI SFONDO

export const ContentPanelTitle = ({ color, fontWeight="bold", children }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const styleContext = useContext(StyleContext);

  color = color ? color : styleContext.getContrastText(theme);

  return (
    <Fade in={true} timeout={1000}>
      <Typography
        variant={isSmallScreen ? "h3" : "h2"}
        gutterBottom
        align="center"
        fontWeight={fontWeight}
        color={color}
        //fontSize={isSmallScreen ? "3rem" : "4rem"}
        sx={{ mb: isSmallScreen ? "0rem" : "1rem" }}
      >
        {children}
      </Typography>
    </Fade>
  );
};

export const ContentPanel = ({
  margin,
  padding,
  borderRadius,
  backgroundImageSrc,
  color,
  centerContent,
  children,
}) => {
  const titleChild = React.Children.toArray(children).find((child) => {
    return child.type === ContentPanelTitle;
  });

  const contentChildren = React.Children.toArray(children).filter((child) => {
    return child.type !== ContentPanelTitle;
  });

  const areAllRowChildren = contentChildren.every((child) => {
    return child.type === Row;
  });

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const theme = useTheme();
  color = color ? color : theme.palette.background.default;

  const backgroundImageStyle = backgroundImageSrc
    ? {
        backgroundImage: `url(${backgroundImageSrc})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }
    : {};


  const containerStyle = {
    ...backgroundImageStyle,
    backgroundColor: color,
    py: padding || "5rem",
    px: isSmallScreen && "0rem",
    borderRadius: borderRadius || 0, // Add border radius
    margin: margin || 0, // Add margin
    display: "flex", // Center the container horizontally
    justifyContent: "center", // Center the container horizontally
  };

  return (
    <StyleProvider value={{ backgroundColor: color }}>
      <Container sx={containerStyle} maxWidth={false}>
        <Container maxWidth={isSmallScreen? false : "lg"}>
          {titleChild}
          {!areAllRowChildren && <Row>{contentChildren}</Row>}
          {areAllRowChildren && <Stack>{contentChildren}</Stack>}
        </Container>
      </Container>
    </StyleProvider>
  );
};

/**
 * This component allows you to create a row of content, where de content is centered and the children are spaced out.
 * @param {*} children
 * @param {*} spacing
 * @param {*} weights the weights of the childern in the row on a base 12 grid(e.g. [3, 6, 3] will make the first and last child 3/12 of the row and the second child 6/12 of the row)
 *
 */
export const Row = ({ children, spacing, weights = null, center=true, sx }) => {
  const direction = "row";

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const childCount = React.Children.toArray(children).filter(child => child !== undefined && child !== null).length;
  const baseChildGridItemSpace = Math.floor(12 / childCount);

  return (
    <Box sx={{...sx}}>
      <Grid
        container
        //spacing={spacing || (isSmallScreen ? "4rem" : "3rem")}
        direction={direction}
        justifyContent="center" // Add this line
        alignContent="center" // Add this line
      >
        {React.Children.map(children, (child, childIndex) => {
          const itemGridSpace = weights
            ? weights[childIndex]
            : baseChildGridItemSpace;
          return (
            child && <Grid
              item
              container
              xs={12}
              md={itemGridSpace}
              justifyContent={center || isSmallScreen ? "center" : "flex-start"}
              alignContent={center || isSmallScreen ? "center" : "flex-start"}
              padding={spacing || "2rem"}
            >
              {child}
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

/**
 * This component allows you to create a column of content, where de content is centered and the children are spaced out.
 * @param {*} children
 * @param {*} spacing
 * @param {*} weights the weights of the childern in the column on a base 12 grid(e.g. [3, 6, 3] will make the first and last child 3/12 of the column and the second child 6/12 of the column)
 *
 */

export const Col = ({ children, spacing, weights = null, center=true, sx}) => {

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const direction = "column";

  const childCount = React.Children.toArray(children).filter(child => child !== undefined && child !== null).length;
  const baseChildGridItemSpace = Math.floor(12 / childCount);

  // use a 12 row based sistem to define the space of the children


  return (
    <Box
      display="flex"
      flexDirection={direction}
      width={"100%"}
      height={"100%"}
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      justifyItems="center"
      sx={{...sx}}
    >
      {React.Children.map(children, (child, childIndex) => {
        const itemGridSpace = weights
          ? weights[childIndex]
          : baseChildGridItemSpace;
        return (
          child && <Box
            width={"100%"}
            height={"" + itemGridSpace * 8.33 + "%"} // 8.33 is 100/12
            margin={spacing || (isSmallScreen ? "1rem" : "0rem")}
            display="flex"
            flexDirection={"row"}
            justifyContent = {center || isSmallScreen? "center" : "flex-start"}
            justifyItems = {center || isSmallScreen? "center" : "flex-start"}
          >
            {child}
          </Box>
        );
      })}
    </Box>
  );
};
