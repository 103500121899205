import { JarsickWebsiteButton, JarsickWebsiteIconButton } from "./jarsick_website_button";
import { ReactComponent as TikTokIconSvg } from "../vectors/tiktok-icon.svg";
import { ReactComponent as XIconSvg } from "../vectors/x-icon.svg";
import { ReactComponent as InstagramIconSvg } from "../vectors/instagram-icon.svg";
import { ReactComponent as YouTubeIconSvg } from "../vectors/youtube-icon.svg";
import { ReactComponent as SteamIconSvg } from "../vectors/steam-icon.svg";

import { useTheme } from "@emotion/react";
import { IMAGE_PUBLIC_PATH } from "../constants";
import { Image } from "../component_library/image";

export function TikTokButton({ sx, color }) {
    return (
        <JarsickWebsiteIconButton
            onClick={() => window.open("https://www.tiktok.com/@disaster.blaster.game")}
            icon={<TikTokIconSvg/>}
            color={color}
            sx = { sx }
        />
    );
}

export function YouTubeButton({ sx, color }) {
    return (
        <JarsickWebsiteIconButton
            onClick={() => window.open("http://www.youtube.com/@jarsickgames6422")}
            icon={<YouTubeIconSvg/>}
            color={color}
            sx = { sx }
        />
    );
}

export function XButton({ sx, color }) {
    return (
        <JarsickWebsiteIconButton

            onClick={() => window.open("https://x.com/JarsickGames")}
            icon={<XIconSvg/>}
            color={color}
            sx = { sx }
        />
    );
}

export function InstagramButton({ sx, color }) {
    return (
        <JarsickWebsiteIconButton
            onClick={() => window.open("https://www.instagram.com/jarsick_official")}
            icon={<InstagramIconSvg/>}
            color={color}
            sx = { sx }
        />
    );
}

export function DisasterBlasterSteamButton( {sx, color} ) {
    const theme = useTheme();
    return (
        <JarsickWebsiteIconButton
            onClick={() => window.open("https://store.steampowered.com/app/3194600/Disaster_Blaster/")}
            icon={<SteamIconSvg/>}
            color={color}
            sx = { sx }
            blink = { true}
        />
    );
}

export function DisasterBlasterItchioButton({width, height}) {
    const theme = useTheme();
    return (
        <JarsickWebsiteButton
            onClick={() => window.open("https://jarsick.itch.io/disaster-blaster-demo")}
            size="small"
            variant="text"
            color={theme.palette.primary.main}
            sx={{ width: width || "100%", height: height || "100%" }}
        >
            <Image src={`${IMAGE_PUBLIC_PATH}/itchio-logo.png`} />
        </JarsickWebsiteButton>
    );
}