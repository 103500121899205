import React from 'react';
import { Helmet } from 'react-helmet';
import { DisasterBlasterWikiBanner } from "../components/disasterblaster_wiki_banner";
import { ContactInfoSection } from '../components/contact_info_section';
import { ContentPanel, ContentPanelTitle } from '../component_library/layout';
import { Paragraph, ParagraphBody, ParagraphTitle, toHTML } from '../component_library/paragraph';
import { Localization } from '../component_library/localization';

function IntroSection() {
    return (
        <ContentPanel>
            <ContentPanelTitle>
                {Localization.get("db_wiki_blaster_title")}
            </ContentPanelTitle>
            <Paragraph>
                <ParagraphBody>
                    {Localization.get("db_wiki_blaster_intro")}
                </ParagraphBody>
            </Paragraph>
        </ContentPanel>
    );
}

function BlasterSlotsSection() {
    return (
        <ContentPanel>
            <Paragraph>
                <ParagraphTitle>
                    {Localization.get("db_wiki_blaster_slots_title")}
                </ParagraphTitle>
                <ParagraphBody>
                    {toHTML(Localization.get("db_wiki_blaster_slots"))}
                </ParagraphBody>
            </Paragraph>
        </ContentPanel>
    );
}

function BlasterDisplaySection() {
    return (
        <ContentPanel>
            <Paragraph>
                <ParagraphTitle>
                    {Localization.get("db_wiki_blaster_display_title")}
                </ParagraphTitle>
                <ParagraphBody>
                    {toHTML(Localization.get("db_wiki_blaster_display"))}                  
                </ParagraphBody>
            </Paragraph>
        </ContentPanel>
    );
}

function TipsSection() {
    return (
        <ContentPanel>
            <Paragraph>
                <ParagraphTitle>
                    {Localization.get("db_wiki_blaster_tips_title")}
                </ParagraphTitle>
                <ParagraphBody>
                    {toHTML(Localization.get("db_wiki_blaster_tips"))}
                </ParagraphBody>
            </Paragraph>
        </ContentPanel>
    );
}

export function DisasterBlasterWikiBlasterPage() {

    return (
        <>
            <Helmet>
                <title>Disaster Blaster Wiki - The blaster</title>
                <meta name="description" content="In Disaster Blaster, the primary goal is to build the perfect blaster that allows you to reach the end of each level." />
                <link rel="canonical" href="https://www.jarsick.com/disasterblaster/wiki/blaster" />
            </Helmet>
            <DisasterBlasterWikiBanner />
            <IntroSection />
            <BlasterSlotsSection />
            <BlasterDisplaySection />
            <TipsSection />
            <ContactInfoSection />
        </>
    );
}