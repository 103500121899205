import { useTheme } from "@emotion/react";
import { Carousel } from "../component_library/carousel";
import { ContentPanel, ContentPanelTitle, Row } from "../component_library/layout";
import { Localization } from "../component_library/localization";
import { toHTML } from "../component_library/paragraph";
import { NewsCard } from "./news_card";
import { Box, useMediaQuery } from "@mui/material";
import { IMAGE_PUBLIC_PATH } from "../constants";

export function NewsSection({backgroundColor}) {
    const backgroundImg = `${IMAGE_PUBLIC_PATH}/news_section_background.png`;
    const theme = useTheme();
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

    const newsList = [
        {
            img: `${IMAGE_PUBLIC_PATH}/db_demo_banner.jpg`,
            title: toHTML(Localization.get("disaster_blaster_demo_coming_soon_title")),
            mdFilePath: "markdowns/news_disaster_blaster_demo_coming_soon.md"
        },
        {
            img: `${IMAGE_PUBLIC_PATH}/demo-out.jpg`,
            title: toHTML(Localization.get("news_disaster_blaster_demo_out_title")),
            mdFilePath: "markdowns/news_disaster_blaster_demo_out.md"
        }
    ];

    const newsCards = newsList.map((news, index) => (
        <NewsCard
            key={index}
            img={news.img}
            title={news.title}
            mdFilePath={news.mdFilePath}
        />
    ));

    return (
        <Box id="news">
            <ContentPanel 
                backgroundImageSrc={backgroundImg} 
                color={backgroundColor ? backgroundColor : theme.palette.background.default} 
            >
                <ContentPanelTitle>{Localization.get("latest_news")}</ContentPanelTitle>
                {isSmallScreen ? (
                    <Carousel>
                        {newsCards}
                    </Carousel>
                ) : (
                    newsCards
                )}
            </ContentPanel>
        </Box>
    );
}